/*----- footer css starts --------*/

.main-footer {
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 50px;
    line-height: 50px; /* Vertically center the text there */
    background-color: #3d4643;
  }

  .copyright-text {
    font-size: 15px;
    color: #fff;
    float: right;
    margin-bottom: 0;
    }

    .copyright-text > a{
        color: #ff9e30;
    }


  
/*----- footer css ends --------*/
