.my-lists {
    list-style: none;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    margin-bottom: 0;
    width: 100%;
    padding-left: 0;
}

.list-view {
    padding: 10px;
    border: 5px solid #efeded;
    width: 100%;
    float: left;
    background-color: #fff;
}

.list-view-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

.file-info {
    width: 25%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.list-view .file-name {
    color: #404040;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.completed-file-status,
.queued-file-status,
.running-file-status,
.failed-file-status {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.queued-file-status,
.running-file-status {
    color: #ff9e2f !important;
}

.failed-file-status {
    color: #ce1c1c;
}

.completed-file-status {
    color: #397b39 !important;
}


.delivery_status {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    padding: 0 0 0 5px;
}

.no_risk {
    color: green;
}

.delivery_status.moderate_risk {
    color: #ffa500;
}

.delivery_status.high_risk {
    color: #ff0000;
}

/* ---- result table css -----*/
.analysis-billable-table {
    width: 50%;
    display: flex;
    margin-right: 4px;
    align-items: flex-start;
}

.result-container {
    width: 50%;
}

.analysis-result-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #b7b7b745;
    border-radius: 3px;
}

.analysis-result-container>div:first-child {
    text-align: center;
    font-size: 16px;
    padding: 4px 0;
    letter-spacing: 0.3px;
    background-color: #ddd7f9bd;
}

.analysis-info-table {
    margin-bottom: 0;
    background-color: #fff;
}

.analysis-info-table table {
    margin-bottom: 0;
}

.analysis-info-table td {
    font-size: 15px;
    color: #777;
    padding: 4px 5px 7px 5px;
    vertical-align: top;
    text-align: left;
}

.analysis-billable-table>table {
    width: 40%;
}

.analysis-info-table td:first-child {
    font-size: 15px;
}

.analysis-info-table td:nth-child(2) {
    color: #505050;
    text-align: right;
    padding-right: 10px;
}

.analysis-info-table .valid-info-table {
    width: 48%;
    border-right: 1px dashed hsla(0, 0%, 71.8%, .27) !important;
}

.analysis-info-table .analysis-tab-info {
    width: 52%;
}

.deliverability-results-info-table {
    margin-bottom: 5px;
    /* max-width: 93%; */
}

.deliverability-results-info-table td {
    padding: 2px 0;
    vertical-align: middle;
}

.deliverability-results-info-table td:nth-child(1) {
    font-size: 15px;
}

.deliverability-value {
    margin: 0;
    line-height: normal;
    font-weight: 600;
    font-size: 14px;
    word-break: break-all;
    text-align: right;
}

.result-value {
    margin: 0;
    line-height: normal;
    font-weight: 600;
    font-size: 15px;
    word-break: break-all;
}

/* ---- result table css ends-----*/

.list-view-container .file-upload-progress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.list-view-container .file-upload-progress .CircularProgressbar {
    height: 140px;
}

.chart-data-info {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.file-uploaded-date {
    color: #777;
    line-height: normal;
    padding-top: 2px;
    margin-bottom: 2px;
    line-height: normal;
    font-size: 14px;
}

.progress-info-div {
    width: 30%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    /* margin-top: 10px; */
    align-self: center;
}

.list-uploaded-file-img {
    color: #6fa51f;
    display: inline-block;
}

.list-uploaded-file-img svg {
    /* height: 25px; */
    width: 30px;
    fill: currentColor;
    margin-right: 5px;
    vertical-align: sub;
}

.list-type-filter {
    width: 140px;
}

.list-type-filter .css-15k3avv {
    z-index: 2 !important;
}

.credits-req-span {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
}

.lists-filters {
    padding: 0;
    display: flex;
    width: 91%;
    margin-right: 10px;
    flex-direction: column;
}

.filter-comp {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    display: flex;
    width: 95%;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
}

.filter-badge {
    border: 1px solid #e8e8e8;
    color: #4a4a4a;
    font-weight: normal;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    padding: 5px 8px;
    background-color: #e8e8e8;
}

.filter-class {
    line-height: normal !important;
}

div.filter-class>button {
    padding: 8px 15px;
    border: none;
    font-size: 16px;
    border-right: 1px solid #ced4da;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    background: #eee !important;
}

div.filter-class>button:hover {
    border: none;
}

div.filter-class>button:focus {
    border: none;
}

.list-selected-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.filter-reset-btn {
    /* width: 5%; */
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #9E9E9E;
    border-color: #9E9E9E;
    border-radius: 0;
    font-size: 16px;
    padding: 0px 9px;
}

.filter-reset-btn:hover {
    background-color: #b1b1b1;
    border-color: #b1b1b1;
}

.filter-reset-btn:focus {
    box-shadow: none;
}

.filter-menu-item>div {
    font-size: 15px;
}

.filters-div {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-start;
}

.filters-div .btn-clearout-phone-refresh {
    padding: 7px 10px !important;
}

/* download modal css */
.download-result-modal {
    width: 768px;
    max-width: 768px;
}

.download-result-modal .modal-footer {
    background-color: #fff;
}

.download-result-modal .modal-body {
    padding-top: 10px;
    padding-bottom: 0px;
}



.download-option-desc.selected-option {
    display: block;
    padding: 10px 10px;
}

.download-option-desc {
    display: none;
}

.append-checkbox {
    font-size: 15px;
    line-height: normal;
    padding: 8px 10px 8px 40px;
    border-bottom: 1px solid #f4f5f7;
    border-right: 1px solid #f4f5f7;
    width: 100%;
}

.append-checkbox .custom-control-label {
    padding-top: 11px;
    padding-bottom: 10px;
}

.append-checkbox .custom-control-label::before,
.append-checkbox .custom-control-label::after {
    margin-top: 10px;
    margin-bottom: 10px;
}

.append-checkbox-div {
    padding-right: 0;
    padding-left: 0;
}

.download-option-desc .option-desc {
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
    color: #636363;
}

.download-option-desc .option-heading {
    margin-bottom: 8px;
    color: #868686;
    font-size: 23px;
}

.download-option-div {
    background-color: whitesmoke;
    padding-left: 0;
}

.filter-checkbox-values {
    font-size: 14px;
    padding: 5px 5px 5px 25px;
}

.filter-checkbox {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.filter-val {
    font-weight: 600;
    line-height: normal;
    align-self: center;
    margin-bottom: 2px;
}

.append-checkbox .custom-control-label {
    width: 100%;
}

div.append-checkbox-div .custom-control-label:hover {
    cursor: pointer;
}

#all_result+.custom-control-label {
    padding-bottom: 0;
}

#custom+.custom-control-label {
    padding-bottom: 0;
}

.all-result-text {
    font-size: 13px;
    padding-left: 22px;
}

.custom-text {
    font-size: 13px;
    padding-left: 22px;
}

.append-checkbox-values {
    font-size: 14px;
}

.export-list-modal .modal-body {
    padding-top: 10px;
}

.export-list-modal {
    width: 600px;
    max-width: 650px;
}

.close-icon {
    text-align: center;
    font-size: 14px;
    line-height: normal;
    display: inline-block;
    margin-left: 10px;
}

.close-icon:hover {
    cursor: pointer;
}

.close-icon:before {
    content: 'x';
    font-weight: bold;
    color: #6d6969;
    line-height: normal;
    vertical-align: top;
}

.custom-status-filter {
    background-color: #fff;
    padding: 5px 15px;
    margin-top: 5px;
    margin-bottom: 10px;
}

@media(max-width: 768px) {

    .result-container {
        width: 100%;
    }

    .file-info,
    .analysis-result-container,
    .file-info>div {
        width: 100%;
    }

    .progress-info-div>button,
    .progress-info-div,
    .chart-data-info {
        width: 100%;
        margin-top: 10px;
    }

    .deliverability-results-info-table {
        max-width: 100%;
    }

    .lists-filters>div:first-child,
    .filters-div,
    .list-view-container {
        flex-direction: column;
    }

    .lists-filters {
        width: 100%;
        margin-right: 0;
    }

    .filter-comp {
        flex-direction: column;
        border-right: 1px solid #ced4da;
        width: 100%;
    }

    .filter-class,
    .filter-badge {
        margin-bottom: 8px;
    }

    .filter-reset-btn {
        padding: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    div.filter-class>button {
        width: 100%;
        text-align: center !important;
        float: none !important;
    }

    .filters-div .btn-clearout-phone-refresh {
        width: 100%;
        margin-top: 5px;
    }

    .analysis-result-container {
        margin-top: 5px;
    }

    .download-result-modal,
    .export-list-modal {
        width: auto;
    }
}

.file-expire-date {
    text-align: center;
    font-weight: 600;
    color: #404040;
    font-size: 12px;
}