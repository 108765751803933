.app-container {
    background-color: #f6f6f6;
    float: left;
    width: 100%;
}

.btn-clearout {
    background-color: #ff9e30;
    border-color: #ff9e30;
    color: #fff;
    font-weight: 700;
}

.btn-clearout:focus,
.btn-clearout:hover {
    background-color: #fd8b08;
    border-color: #fd8b08;
    color: #fff
}

.btn-clearout:focus {
    outline: none;
    outline-color: transparent;
    box-shadow: none;
}

.btn-clearout.disabled,
.btn-clearout:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.btn-clearout-link {
    background-color: transparent;
    border: none;
    /* text-decoration: underline !important; */
    color: #03a9f4 !important;
    font-weight: normal;
}

.btn-clearout-link:hover {
    cursor: pointer;
}

.btn-clearout-link:focus {
    color: #0b8df8;
    outline: none;
    box-shadow: none;
}

.error-message {
    color: #ff0000;
    margin-bottom: 0;
    font-size: 14px;
    margin-left: 3px;
    /* text-transform: capitalize; */
}

.paste-email-error {
    color: #0059ff;
    margin-bottom: 0;
    font-size: 14px;
    margin-left: 3px;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-bottom: 0;
}

.unknown-error-message {
    color: #808080;
    font-size: 14px;
    margin-bottom: 0;
}

.no-padding {
    padding: 0;
}

.text-clearout {
    color: #fd8b08;
    font-weight: 600;
    font-size: 15px;
}

.btn-clearout-phone-export,
.btn-clearout-phone-download {
    /* width: 240px;
    max-width: 240px; */
    align-self: stretch;
}

.btn-input-file-download {
    color: #00bcd4;
    padding: 0;
    margin-left: 5px;
    padding: 2px 3px 2px 5px;
}

.btn-input-file-download:hover {
    border-color: #00bcd4;
    color: #00bcd4;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: none;
}

input.credit-input:focus {
    box-shadow: 0 0 10px 1px #f8b467 !important;
    border: 1px solid #f8b467 !important;
}

input.coupon-input {
    /* box-shadow: 0 0 10px 1px #f8b467 !important; */
    border: 1px solid #f8b467 !important;
}

input.coupon-input:focus {
    box-shadow: 0 0 10px 1px #f8b467 !important;
    border: 1px solid #f8b467 !important;
}

.btn-clearout-phone-refresh {
    /* padding: 3px 5px; */
    /* outline: none; */
    box-shadow: none;
    color: #717171 !important;
    border-color: #c3c3c3 !important;
}

/* .btn-clearout-phone-refresh:hover,
.btn-clearout-phone-refresh:focus {
    outline: none;
    box-shadow: none;
    border-radius: 0;
    color: #717171 !important;
    background-color: #fff;
    border-radius: 4px;
} */

.btn-outline-secondary {
    color: #717171 !important;
    border-color: #c3c3c3 !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
}

.btn-no-data {
    padding: 0px;
}

/* to remove auto complete input filed backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.notification.notification-success.notification-visible {
    border-top: 2px solid #6200EA !important;
    background-color: #ddddffb3 !important !important;
    color: rgb(75, 88, 58) !important;
    box-shadow: rgba(253, 211, 133, 0.9) 0px 0px 1px !important;
}

.notification-title {
    color: #6200EA !important;
}

.notification-dismiss {
    background-color: #6200EA !important;
    color: #ffe9cd !important;
}

button:disabled {
    cursor: not-allowed;
}

.modal-footer {
    border-top: none;
    background-color: whitesmoke;
    padding: 8px 5px;
}

.download-btn-shadow {
    background-color: #fd8b08;
    border-color: #fd8b08;
    color: #fff;
    text-decoration: underline;
}

.word-break-all {
    word-break: break-all;
}

.word-break-word {
    word-break: break-word;
}

.list-info-clearout-breadcrumb {
    display: flex;
    justify-content: space-between;
}

.clearout-breadcrumb .breadcrumb,
.list-info-clearout-breadcrumb .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
}

.list-info-clearout-breadcrumb .breadcrumb .breadcrumb-item,
.clearout-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 17px;
    line-height: normal;
}

.list-info-breadcrumb>.breadcrumb {
    padding: 10px;
}

.readMoreText {
    display: inline-block;
    padding: 0 .5em;
    font-size: 15px;
    line-height: 1;
    text-decoration: underline;
    font-weight: 400 !important;
}

.clearout-danger-btn {
    color: #fff !important;
    background-color: #c54a41 !important;
    border-color: #c54a41 !important;
    box-shadow: none;
    font-weight: 700;
}

.co-btn-toolbar {
    justify-content: flex-end;
}

.react-bootstrap-table-pagination {
    margin-top: 10px;
}

@media(max-width: 768px) {
    .list-info-clearout-breadcrumb {
        flex-direction: column;
    }

    .co-btn-toolbar {
        margin-top: 10px;
    }

    .co-btn-toolbar>button {
        flex-basis: 0;
        flex-grow: 1;
    }

    .btn-clearout-phone-export,
    .btn-clearout-phone-download {
        width: 100%;
        max-width: 100%;
    }
}

.custom-toggle-notification.react-toggle--checked .react-toggle-track {
    background-color: #0562af;
}

.custom-toggle-notification .react-toggle-track {
    background-color: #b3b3b3;
    height: 18px;
    width: 37px;
}

.custom-toggle-notification .react-toggle-thumb {
    border: transparent;
}

.custom-toggle-notification .react-toggle-thumb {
    width: 15px;
    height: 15px;
}

.custom-toggle-notification.react-toggle--checked .react-toggle-thumb {
    left: 20px;
}

.custom-toggle-notification.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #0562af;
}

.custom-toggle-notification .react-toggle-track-check {
    width: 14px;
    height: 0px;
    left: 5px;
    color: #fff;
}

.notification-text {
    font-size: 14px;
    margin: 0px;
    line-height: 1.4;
}


.remove-input-file {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #a74a2d !important;
    padding: 0;
    align-self: center;
    font-size: 15px;
}

.show-password-button,
.hide-password-button {
    position: absolute;
    top: 1em;
    right: .5em;
    background: 0 0;
    border: none;
    font-size: .835rem;
    color: #9faebb;
    padding-left: .25em;
    -webkit-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    cursor: pointer;
}

.show-password-button::before {
    content: "\F06E";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 1.5em;
    position: absolute;
    top: 0px;
    left: -1em;
}

.hide-password-button::before {
    content: "\F070";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 1.5em;
    position: absolute;
    top: 0px;
    left: -1em;
}

.show-password-button:focus,
.hide-password-button:focus {
    outline: none;
}

.edit-pwd-modal .show-password-button::before,
.edit-pwd-modal .hide-password-button::before {
    top: 12px !important;
    left: -27px !important;
}

#paypal-button-container {
    width: 100%;
}

.buy-credits-modal #paypal-button-container {
    width: 70%;
    float: right;
}