/*----- header css --------*/
.main-header{
    /* background: #3e5151 linear-gradient(27deg,#3e5151 0,#decba4); */
    background:#0909b9 linear-gradient(27deg,#d1d1ff 0,#1515c8);
}

.main-header > nav{
    color: #fff;
}

.main-header .navbar-nav .nav-item > a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 20px 18px;
    display: block;
}

.main-header .navbar-nav .nav-item > a.active{
    /* background-color: #858775; */
    background:rgba(0, 0, 0, 0.13);
}

.main-header .navbar-nav .nav-item > a:hover{
    cursor: pointer;
    text-decoration: none;
}

.main-header .navbar-toggler {
    color: #fff;
    padding: 0;
}

.main-header .navbar-toggler-icon {
    background-image: url('./../svg/navbar-toggle.svg');
}

.main-header .logo-default{
    height: 100%;
    width: 225px;
}

.logo-default{
    height: 100%;
    width: 225px;
}

.main-header .navbar{
    padding: 0px;
}

.main-header .navbar-toggler:focus{
    outline: none;
}

.announcekit-frame-wrapper {
    top: 0!important;
    right: 0!important;
}

.header-item{
    font-size: 16px !important;
    padding: 22px 18px !important;
}

.custom-header-tooltip {
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    max-width: 350px;
    z-index: 124586;
    opacity: 0.6;
    word-break: break-word;
}


@media(max-width: 768px) {
    .main-header .navbar{
        padding: 8px 16px;
    }
    .main-header .navbar-nav .nav-item > a{
        padding: 5px 10px;
    }
    .main-header .navbar-nav .nav-item > a.active{
        border-bottom: 0px;
    }

    .header-item{
        font-size: 1.25rem !important;
        padding-top: 0.3125rem !important;
        padding-top: 0.3125rem !important;
        padding: 0 !important;
        padding-right: 5px !important;
    }
}
/*----- header css ends --------*/
