@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);
@import url('https://fonts.googleapis.com/css?family=Lekton');
/* @import url(https://fonts.googleapis.com/css?family=Comfortaa&display=swap); */

@import url('./header.css');
@import url('./footer.css');
/* @import url('./form_ui.css'); */
@import url('./custom-pagination.css');
@import url('./mylists.css');
@import url('./admin.css');
@import url('./announcement-bar.css');

body {
    color: #404040;
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    /* font-family: 'Comfortaa', cursive; */
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

#main-container {
    padding: 0;
}

.page-header {
    padding-top: 15px;
    padding-bottom: 15px;
}

.page-sub-header {
    padding-top: 5px;
    padding-bottom: 0px;
}

.page-panel-heading {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-panel-heading>h3 {
    font-size: 18px;
    margin: 0;
    color: #7b7b7b;
}

.page-main-heading {
    margin-bottom: 0;
    font-size: 28px !important;
    color: #4c4c4c !important;
}

.page-sub-heading {
    font-size: 15px;
    color: #4c4c4c;
    margin-bottom: 0;
    line-height: normal;
    font-weight: 600;
}

.page-sub-header-nav .nav-item>a.nav-link,
.page-sub-header-nav .nav-item>button {
    padding: 7px 8px;
    margin-right: 10px;
    background-color: #6200EA;
    border-radius: 4px;
    border: 1px solid #6200EA;
    font-size: 14px;
    color: #f9f7f5;
    font-weight: 700;
    font-family: 'SofiaProRegular', sans-serif;
}

.page-sub-header-nav .nav-item:last-child>a.nav-link {
    margin-right: 0;
}

.page-container {}

.page-content {
    padding: 0;
}

/* overview page css */
.overview-page-content {
    background-color: #fff;
    margin-bottom: 15px;
    /* padding: 10px; */
    border: 1px solid #f5f5f5;
}

.page-panel-content {
    padding: 15px;
}

.line-chart-preset {
    display: flex;
    list-style: none;
    justify-content: center;
    margin-top: 10px;
    flex-wrap: wrap;
}

.line-chart-preset li {
    font-size: 12px;
    font-weight: 700;
    background-color: #ff9e2f;
    color: #fff;
    padding: 0;
    border-radius: 3px;
    margin-right: 15px;
    margin-top: 5px;
}

.line-chart-preset li>a {
    padding: 3px 5px 2px 5px;
}

.line-chart-preset li:hover {
    cursor: pointer;
}

.profile-page-content {
    margin-bottom: 15px;
    /* padding: 10px 0; */
    display: flex;
    flex-wrap: wrap;
}

.profile-page-content .infobox {
    background-color: #fff;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 1px solid #f5f5f5;
}

.profile-form-data-display,
.card-details-display,
.credit-details-display {
    margin-bottom: 0;
    /* font-size: 15px; */
}

.profile-form-data-display td,
.card-details-display td,
.credit-details-display td {
    padding: 5px 5px 0 5px;
}

.profile-form-data-display .profile-name p,
.profile-form-data-display .profile-emailaddress p,
.card-details-display .card-data p,
.credit-details-display .credit-data p {
    margin-bottom: 0;
}

.profile-form-data-display .profile-emailaddress {
    text-align: right;
}

.edit-email-modal .modal-header {
    padding: 10px 10px;
}

.edit-email-modal .modal-header .modal-title {
    color: #4c4c4c;
}

.cards-list {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0;
}

.profile-page-sidebar {
    background-color: #ffffff;
    margin-right: 10px;
}

.profile-page-sidebar.nav-tabs .nav-item {
    margin-bottom: 0 !important;
}

.profile-page-sidebar.nav-tabs .nav-link.active {
    background-color: transparent;
    color: #6200EA;
    border-right: 3px solid #6200EA;
}

.profile-page-sidebar.nav-tabs .nav-link {
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: #e0e0e0;
    padding: 8px 16px 8px 16px;
    font-size: 17px;
    color: #404040;
}

.profile-page-sidebar li a:hover {
    cursor: pointer;
}

.card-brand-logo {
    width: 60px;
    margin-right: 10px;
}

.card-text {
    margin-right: 10px;
}

.cards-list li {
    font-size: 14px;
    padding: 5px 5px 5px 0;
}

.cards-list li:hover {
    background-color: #f1f1f1;
}

.cards-list .card-text {
    font-size: 15px;
}


/* buy credits modal css */
.buy-credits-modal.modal-dialog {
    max-width: 600px;
}

.buy-credits-modal .modal-header {
    padding: 10px 15px;
}

.buy-credits-modal .modal-header .close {
    padding: 12px 15px;
    margin-top: -9px;
    margin-right: -14px;
}

.buy-credits-modal .modal-footer {
    padding: 15px 15px 0 15px;
    flex-direction: column;
    background-color: #f4f5f7;
}

.buy-credits-modal .credit-modal-content-body>div:nth-child(1) {
    padding: 15px 0;
}

.credit-modal-content-body {
    border-bottom: 1px solid #e0e0e0;
}

.buy-credits-modal .modal-body {
    padding: 0px;
}

.buy-credits-modal .cards-list-container {
    padding: 10px;
}

.buy-credits-modal .add-card {
    max-width: 500px;
    margin: 0 auto;
}

.buy-credits-modal .card-images>li {
    list-style: none;
    margin-top: 3px;
}

.buy-credits-modal .card-images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1px;
    justify-content: center;
}

.buy-credits-modal .pricing-card-images {
    margin-top: 15px;
    background-color: whitesmoke;
    padding: 10px;
}

.clearout-help-container {
    color: #999;
    font-size: 13px;
    line-height: 1.5;
    margin: 0 0 10px;
    line-height: normal;
}

.clearout-help-container>ul {
    margin: 0;
    padding-inline-start: 15px;
}

.overview-stats {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.performance-stats {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.performance-stats .stat-heading {
    display: block;
    text-align: left;
    font-size: 15px;
    color: #484848;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: "open_sanssemibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    letter-spacing: 0.6px;
}

.performance-stats .stat-value {
    display: block;
    font-size: 40px;
    color: #4c4c4c;
    margin-bottom: 0;
}

.performance-stats .stats-bar {
    height: 3px;
    background: linear-gradient(to right, #00bcd4 0%, #6a9bcd 50%);
    width: 75%;
}

.performance-stats-heading {
    text-align: center;
    font-size: 30px !important;
    color: #334a4a !important;
    font-weight: 400;
    line-height: 1.2;
}

.versioncheck.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

.custom-timezone-picker>input {
    width: 100% !important;
    border-radius: 4px !important;
    border: 1px solid #ced4da !important;
}

.custom-timezone-picker>ul.open li>button {
    padding: 8px;
    background-color: #fff;
}

.custom-timezone-picker>ul.open li>button:hover {
    background-color: whitesmoke;
}

.custom-timezone-picker>ul>li {
    background-color: #fff;
}

.min-credits-note {
    font-size: 14px;
    margin: 0 auto;
    margin-bottom: 0;
    margin-top: 5px;
}

.max-emails-note {
    font-size: 14px;
    max-width: 900px;
    margin: 0px auto;
    margin-top: 5px;
}

.total-breakup {
    margin-right: 0 !important;
    justify-content: flex-end;
    width: 100%;
}

.total-breakup-table {
    max-width: 290px;
    margin-left: auto;
    margin-bottom: 5px;
    background-color: inherit !important;
}

.total-breakup-table th,
.total-breakup-table td {
    padding: 0 0 5px 10px;
    font-size: 15.5px;
    color: #3d4042;
    text-align: right;
}

.total-breakup-table .total-value td,
.total-breakup-table .total-value th {
    font-size: 16px;
    color: #3d4042;
    font-weight: bold;
}

.stripe-logo {
    width: 110px;
}

.support-text {
    font-size: 12px;
    margin: 0px;
    letter-spacing: 0.3px;
    line-height: 1.4;
    padding-top: 5px;
}

.support-text>span {
    color: #fd8b08;
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
    font-weight: 700;
}

.custom-pricing-text {
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0;
}

.custom-pricing-text>a {
    color: #FF9800;
    font-weight: bold;
}

.api-token-input {
    font-family: 'Lekton', sans-serif;
    font-size: 15px;
    color: #000000;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.api-token-input:focus {
    background-color: #fff !important;
    outline: 0 !important;
    box-shadow: none !important;
    border-right: none;
}

.api-key-copied {
    color: #6fa51f;
    display: inline-block;
}

.api-key-copied svg {
    height: 20px;
    width: 20px;
    fill: currentColor;
    vertical-align: sub;
}

.alert-modal .modal-header {
    padding: 10px 15px;
}

.error-svg {
    color: #ef594d;
    display: inline-block;

}

.error-svg svg {
    height: 50px;
    width: 50px;
    fill: currentColor;
    padding-right: 10px;
    vertical-align: sub;
}

.home-svg {
    color: #fff;
    display: inline-block;
}

.home-svg svg {
    height: 25px;
    width: 25px;
    fill: currentColor;
    vertical-align: sub;
}

.instant-search-recaptcha {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
}

.list-type-svg {
    color: #6fa51f;
    display: inline-block;
}

.list-type-svg svg {
    height: 28px;
    width: 28px;
    fill: currentColor;
    padding-right: 5px;
    vertical-align: sub;
}

.addlist-upload-svg,
.addlist-mailchimp-svg,
.addlist-acttive-campaign-svg,
.addlist-leads-svg,
.addlist-moosend-svg,
.addlist-hubspot-svg {
    color: #797979;
    display: inline-block;
}

.active .addlist-leads-svg,
.active .addlist-mailchimp-svg,
.active .addlist-acttive-campaign-svg,
.active .addlist-upload-svg,
.active .addlist-moosend-svg,
.active .addlist-hubspot-svg {
    color: #6200EA;
}

.addlist-upload-svg svg,
.addlist-mailchimp-svg svg,
.addlist-acttive-campaign-svg svg,
.addlist-moosend-svg svg,
.addlist-hubspot-svg svg,
.addlist-leads-svg svg {
    height: 30px;
    width: 34px;
    fill: currentColor;
    padding-right: 5px;
    vertical-align: sub;
}

.total-img-div {
    padding: 20px;
    border-radius: 60%;
    border: 1px solid #f19d2f;
    text-align: center;
    margin: 0 auto;
    width: 90px;
}

.verify-icon {
    height: 20px;
    padding-right: 3px;
}

.add-list-modal.modal-dialog,
.quick-validation-modal.modal-dialog {
    max-width: 900px;
}

.quick-validation-modal .modal-content,
.add-list-modal .modal-content {
    height: 500px;
}

.quick-validation-modal .modal-body {
    overflow-y: hidden;
}

.limits-table td {
    padding-top: 0;
    padding-left: 0;
    font-size: 15px;
    word-break : break-all;
}

.limits-table td:first-child {
   width: 150px;
}

.add-list-modal .modal-body {
    overflow-y: auto;
}

.download-checkmark {
    color: #6200EA;
    display: inline-block;
}

.download-checkmark svg {
    height: 50px;
    width: 50px;
    fill: currentColor;
    padding-right: 5px;
    vertical-align: sub;
    margin-bottom: 10px;
}

.download-err-checkmark {
    color: #c54a41;
    display: inline-block;
}

.download-err-checkmark svg {
    height: 50px;
    width: 50px;
    fill: currentColor;
    padding-right: 5px;
    vertical-align: sub;
}

.add-list-modal .modal-body {
    overflow-y: auto;
}

.add-list-page-content {
    display: flex;
    flex-wrap: wrap;
}


@media(max-width: 768px) {
    .versioncheck.modal-dialog {
        min-height: calc(100vh - 20px);
    }

    .profile-page-sidebar {
        flex-direction: row !important;
        justify-content: space-evenly;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .profile-page-sidebar.nav-tabs .nav-link.active {
        border-right: 0px !important;
        border-bottom: 3px solid #6200EA;
    }

    .instant-search-recaptcha {
        justify-content: center !important;
    }

    .page-sub-header-nav {
        margin-top: 10px;
    }

    .add-list-page-content .nav-tabs {
        border-bottom: none;
    }

    .btn-toolbar .account-select-options {
        width: 136px !important;
    }
}

@media (max-width: 992px) {

    .quick-validation-modal.modal-dialog,
    .add-list-modal.modal-dialog {
        max-width: 720px;
    }
}

@media (max-width: 768px) {

    .quick-validation-modal.modal-dialog,
    .add-list-modal.modal-dialog {
        max-width: 560px;
    }

    .limits-table td:first-child {
        width: auto;
    }
}

.account-select-options {
    width: 200px !important;

}

.status-data-quick-validation {
    text-align: center;
    font-size: 16px !important;
    letter-spacing: 1px;
    word-break: break-word;
    /* padding-left: 30px; */
    line-height: normal;
    font-weight: 600;
}

.download-tooltip-info-changes{
    background-color: whitesmoke;
    border-left: 1px solid #e8e8e8;
}

.apply-coupon-div {
    max-width: 290px;
    margin-left: auto;
    float: right;
}

.cancel-coupon{
    border: 1px solid #928c8c !important;
    border-radius: 40px;
    padding: 0 4px !important;
    background-color: #ddd;
    cursor: pointer !important;
    font-size: 19px;
}

.apply-coupon{
    border: 1px solid #58c058;
    border-radius: 40px;
    padding: 0px 4px;
    background-color: #58c058;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    margin-top: 5px;
}

.apply-coupon:focus{
    outline: none;
}

.coupon-code{
    border: 1px dashed #9d9fa0;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 0;
    font-size: 14px;
    color: #828282;
    font-weight: bold;
}

.coupon-discount-input:focus{
    box-shadow: none;
}

.hubspot-note{
    font-size: 13px;
    padding-left: 18px;
}