.users-roles-select{
    width: 200px;
    padding-right: 10px
}

.users-accounts,
.teams-table,.team-members-table {
    background-color: #fff;
}

.users-accounts>table,
.teams-table>table,.team-members-table>table {
    margin-bottom: 0px;
}

.register-phone-number .PhoneInputCountry .PhoneInputCountryIcon  > img{
    vertical-align: super;
}

.adduser-form-headings{
    font-size: 14px;
    margin: 0px;
}

.mandatory-feild-indicator{
    color: red;
}
.limits-column-alignment{
    text-align: right;
}
.credits-column-alignment{
    text-align: center;
}

/* .users-accounts>table>tbody>tr {
    cursor: pointer;
} */

@media(min-width:768px) {

    /* user table fixed column */
    .users-accounts>table .users-page-fixed-column ,
    .team-members-table>table .users-page-fixed-column {
        position: sticky;
        background-color: #fff;
        position: -webkit-sticky;
        left: 0;
        /* z-index: 2; */
    }

    .users-accounts>table>tbody tr.selected-row-bgcolor .users-page-fixed-column,
    .users-accounts>table>tbody>tr.selected-row-bgcolor>td:nth-child(1),
    .team-members-table>table>tbody>tr.selected-row-bgcolor>td:nth-child(1),
    .team-members-table>table>tbody tr.selected-row-bgcolor .users-page-fixed-column{
        position: sticky;
        background-color: #e0e0e0;
        position: -webkit-sticky;
        left: 0;
        border-top: 1px solid #e8e8e8;
        z-index: 2;
    }

    .users-accounts>table>thead>tr>th:nth-child(2),
    .team-members-table>table>thead>tr>th:nth-child(1) {
        position: sticky;
        background-color: #FFF;
        position: -webkit-sticky;
        left: 0;
        border-top: 1px solid #e8e8e8;
        z-index: 2;
    }


    .users-accounts>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column:hover,
    .users-accounts>table>tbody>tr:not(.select-row-bgcolor):hover td,
    .team-members-table>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column:hover,
    .team-members-table>table>tbody>tr:not(.select-row-bgcolor):hover td
    {
        position: sticky;
        background-color: #e0e0e0;
        position: -webkit-sticky;
        left: 0;
        border-top: 1px solid #e8e8e8;
    }

  .users-accounts>table .users-page-fixed-column,
    .users-accounts>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column,
  .team-members-table>table .users-page-fixed-column,
    .team-members-table>table>tbody tr:not(.selected-row-bgcolor) .users-page-fixed-column
     {
        position: sticky;
        background-color: #fff;
        position: -webkit-sticky;
        left: 0;
        z-index: 2;
    }
}



.allow-unlimited-checkbox{
    font-size: 13px;
}

.overview-page-bar {
    background-color: transparent;
    margin-right: 10px;
    margin-left: 10px;
    width: 110px;
    height: 37px;
    padding-top: 3px;
}

.overview-page-bar.nav-tabs .nav-item {
    margin-bottom: 0 !important;
}

.overview-page-bar.nav-tabs{
  border-bottom: 0;
}
.overview-page-bar.nav-tabs #individual.nav-link.active{
    background-color: transparent;
    color: #ff9e30;
    border: 1px solid #ff9e30;
    box-shadow: 0px 0px 2px 0px;
}
.overview-page-bar.nav-tabs #individual.nav-link{
    background-color: transparent;
    color: #b7b7b7;
    font-size: 14px;
    border: 1px solid #b7b7b745;
    border-right: 1px solid #ff9e30;
   
}
.overview-page-bar.nav-tabs #organization.nav-link.active{
    background-color: transparent;
    color: #ff9e30;
    border: 1px solid #ff9e30;
    box-shadow: 0px 0px 2px 0px;
}
.overview-page-bar.nav-tabs #organization.nav-link{
    background-color: transparent;
    color: #b7b7b7;
    font-size: 14px;
    border: 1px solid #b7b7b745;
    border-left: 1px solid #ff9e30;

}

.overview-page-bar.nav-tabs .nav-link {
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: #f9f9f9;
    padding: 5px 10px;
    font-size: 17px;
    color: #404040;
}

.overview-page-bar li a:hover {
    cursor: pointer;
}

.overview-pageheading{
    display: flex;
    padding-left: 0;
}
.overview-pageheading >p{
    margin-bottom: 0;

}
.members-table-search{
    width: 50%;
}


@media(max-width: 768px) {

    .overview-page-bar {
        flex-direction: row !important;
        justify-content: space-evenly;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .overview-page-bar.nav-tabs .nav-link.active {
        border-right: 0px !important;
        border-bottom: 3px solid #ff9e30;
    }
}
