.try-it-now-text>a {
    color: #ffda6a;
    font-weight: bold;
    font-size: 16px;
    /* border-bottom: 1px solid #ffda6a; */
    letter-spacing: 0.2px;
}
.try-it-now-text>button {
    color: #ffda6a;
    font-weight: bold;
    font-size: 16px;
    padding: 2px 10px;
    background: transparent;
    border: 1px solid #ffda6a;
    letter-spacing: 0.2px;
}
.try-it-now-text>button:hover {
    cursor: pointer;
}
.try-it-now-text>button:focus {
    border: 1px solid #ffda6a;
    outline-color: #ffda6a;
}

#notify-bar div.announcement-bar {
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    display: table;
    height: 40px;
}

.close-notification-button {
    color: #fff;
    /* position: absolute; */
    /* top: 0px; */
    padding: 0;
    /* right: 5px; */
    font-size: 27px;
    background-color: transparent;
    width: auto;
    font-weight: 400 !important;
}

.announcement-bar-text {
    /* width: 100%; */
    font-size: 15px;
    font-weight: bold;
    /* padding: 0 10%; */
    /* display: table-cell; */
    /* vertical-align: middle; */
    letter-spacing: 0.1px;

    display: flex!important;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
}

.try-it-now-text {
    width: 35%;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 2em;
}

.skin-blue .no-announcement-bar .main-header .logo {
    top: 0;
}

#notify-bar+.content-body .main-header .navbar-fixed-top,
#notify-bar+.content-body .main-sidebar {
    top: 50px;
}

#notify-bar {
    /* top: 0;
    position: fixed; */
    color: #fff;
    /* background: linear-gradient(175deg, #0acc8b, #337ab7); */
    background: #485957;
    /* height: 50px; */
    width: 100%;
    /* z-index: 9999; */
    animation: slide-up 0.8s ease;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

#toolsSidebar {
    overflow-y: auto;
}

.content-body.no-announcement-bar .main-sidebar #toolsSidebar {
    height: calc(100vh - (50px + 103px + 50px + 14px));
}
/* 50 55 103 58 */
.content-body .main-sidebar #toolsSidebar {
    height: calc(100vh - (50px + 53px + 103px + 58px));
}

.announcement-bar-text p {
    margin: 10px !important;
}

.announcement-bar-text span {
    margin: 10px !important;
}

@media (max-width: 767px) {
    #notify-bar {
        padding: 0 !important;
        height: 90px !important;
    }
    #notify-bar div.announcement-bar {
        padding-top: 5px !important;
        display: block !important;
    }
    .announcement-bar-text {
        max-width: calc(100vw - 38px);
        /* overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
        /* display: block !important; */
        padding-left: 5px !important;
        /* width: 100% !important; */
    }
    .try-it-now-text {
        padding-left: 0;
        width: 100%;
        text-align: center;
        display: block !important;
    }
    .close-notification-button {
        top: 18px;
        left: calc(100vw - 35px) !important;
    }
    .content-body.no-announcement-bar .content-header {
        padding-top: 10px !important;
    }
    #notify-bar+.content-body .main-header .navbar-fixed-top,
    #notify-bar+.content-body .main-sidebar {
        top: 90px !important;
    }
    .content-body .main-sidebar #toolsSidebar {
        height: calc(100vh - (56px + 58px + 103px + 90px + 60px)) !important;

    }
    .content-body.no-announcement-bar .main-sidebar #toolsSidebar {
        height: calc(100vh - (56px + 103px + 56px + 50px)) !important;
    }
}

@media (min-width: 767px) {
    .content-body.no-announcement-bar .content-header {
        padding-top: 10px !important;
    }
}

.sub-heading-upgrade-account{
    color: #868686;
}

.upgrade-popup-right-block{
    background-color: #f7f7f7;
}

.upgrade-popup-right-block p{
    margin: 0;    
}
.features-heading-for-upgrade{
    font-size: 16px;
    font-weight: 600;
}

.upgrade-link-account-page{
    color: #485957;
    /* font-weight: bold; */
    font-size: 18px;
    padding: 4px 10px;
    background: transparent;
    border: 1px solid #ffda6a;
    letter-spacing: 0.2px;
}
.upgrade-link-account-page:hover{
 cursor: pointer;
}

.heading-upgrade-account-right-block{
    font-size: 24px;
}
.options-feature-upgrade{
    color:#868686;
    padding-left: 20px
}
