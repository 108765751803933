.individual-userprofile-img>img {
    width: 130px;
    padding: 3px;
    border: 3px solid #d2d6de;
    height: 120px;
    margin-right: 20px;
}

.individual-user-info-comp {
    margin-bottom: 20px;
}

.individual-user-info-comp>form {
    display: flex;
    justify-content: center;
    background-color: transparent;
    align-items: center;
}

.individual-user-info-view {
    display: flex;
    justify-content: center;
}

.individual-userverify-button {
    margin: 10px 0px;
    background: #fab567;
    border-color: #fab567;
    color: #fff;
    border-radius: 0px;
    padding: 6px 25px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
}

.individual-userverify-button:hover,
.individual-userverify-button:active {
    margin: 10px 0px;
    background-color: #fd8b08;
    border-color: #fd8b08;
    color: #fff;
    border-radius: 0px;
    padding: 6px 25px;
    font-size: 16px;
    letter-spacing: 1px;
}

.individual-userinfo-table {
    width: 100%;
}

.email-input-form {
    /* margin-top: 10px;
    margin-bottom: 2px; */
    letter-spacing: 1px;
    border-color: #f5bd7d;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.email-input-form:focus {
    /* margin-top: 10px; */
    border-color: #f8b467;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 0px 10px 2px #f1c695d7;
}

@media(max-width: 768px) {
    .email-input-form {
        width: 100%;
        border-color: #f8b467;
        border-radius: 0px;
    }

    .individual-user-info-comp>form {
        flex-direction: column;
    }
}

.individual-userinfo-cell-leftside {
    text-align: right;
    letter-spacing: 1px;
    padding-right: 30px;
}

.individual-userinfo-cell-rightside {
    text-align: left;
    font-size: 16px !important;
    letter-spacing: 1px;
    font-weight: 600;
    word-break: break-word;
    padding-left: 30px;
    line-height: normal;

}

.individual-userinfo {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.individual-userinfo-table>tbody>tr>td {
    border: 0.5px dashed #dce0dd94;
    padding: 5px;
    width: 50%;
}

.individual-user-profileinfo {
    color: #ff9e30;
}

/*  for user info copied from internet */
.user-heading {
    width: 100%;
    word-break: break-all;
    padding-bottom: 10px;
    text-align: center;
    margin: 10px 0 0;
}

.profile-email-shown {
    font-size: 20px !important;
    letter-spacing: 1px;
}

.profile-view-row {
    justify-content: center;
}

.profile-info-shown {
    color: orange;
}

.profile-info-unshown {
    color: #808080;
}

.suggested-email-view {
    color: #1a0dab !important;
    cursor: pointer !important;
}

.api-limit-error {
    text-align: center;
}

.bad-request-tryagain {
    color: #007bff;
    cursor: pointer;
}

.bad-request-tryagain:hover {
    text-decoration: underline;
}

.dailylimit-help-tip{
    text-align: center;
    background-color: #4092c3;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 12px;
    line-height: 15px;
    display: inline-block;
    margin-left: 3px;
}

.help-tip {
    text-align: center;
    background-color: #4092c3;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    margin-left: 3px;
}

.help-tip:hover {
    cursor: pointer;
}

.help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.result-name-data:hover .status-help-tip{
        display: inline-block;
}
.result-table-names:hover .status-help-tip{
        display: inline-block;
}

.status-help-tip{
    display: none;
}

.status-help-tip {
    text-align: center;
    background-color: #ccc;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 12px;
    line-height: 20px;
    /* display: inline-block; */
    margin-left: 3px;
}

/* .status-help-tip:hover {
    cursor: url(../img/helpcursor.png), auto;
} */

.status-help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.all-status-help-tip{
    text-align: center;
    background-color: #ccc;
    /* border: 1px solid #ccc; */
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    margin-left: 3px;
}

.all-status-help-tip:hover {
    cursor: pointer;
}

.all-status-help-tip:before {
    content: 'i';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
    font-style: italic;
}

.dailylimit-help-tip .custom-tooltip-helptext{
    font-weight: 500;
}

.dailylimit-help-tip:hover {
    cursor: pointer;
}

.dailylimit-help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
    line-height: normal;
    vertical-align: top;
}

.catch-all-message {
    color: blue;
    margin-bottom: 0;
    font-size: 14px;
}

.in-progress-all-message {
    color: #fab567;
    margin-bottom: 0;
    font-size: 14px;
}

.widget-user {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    /* margin-bottom: 20px; */
    width: 100%;
    /* box-shadow: 0 1px 1px rgba(0,0,0,0.1); */
    margin-top: 50px;
}

.widget-user .widget-user-image {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -45px;
}

.widget-user .widget-user-image>img {
    width: 90px;
    height: auto;
    /* border: 3px solid #fff; */
    border-radius: 50%;
}

.widget-user-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    /* border-top: 1px solid #f4f4f4; */
    /* padding: 30px; */
    background-color: #ffffff;
    padding-top: 30px;
}

.custom-tooltip-helptext.add-user-model-text{
    text-align: left;
    background-color: #505050 !important;
    /* color: #334a4a !important; */
    font-weight: 800;
    width: 170px;
    opacity: 1;
}

.custom-tooltip-helptext {
    text-align: left;
    background-color: #505050 !important;
    /* color: #334a4a !important; */
    font-weight: 800;
    width: 350px;
    opacity: 0.7;
}

.custom-tooltip-helptext:after {
    border-bottom-color: #505050 !important;
}

#deliverability-help-text {
    max-width: 270px;
}

#select-country-help-text {
    max-width: 270px;
}

.top-heading {
    justify-content: center;
}

.top-heading h3 {
    text-align: center;
    font-size: 30px !important;
    /* color: #f8b467 !important; */
    color: #334a4a !important;
    font-weight: 400;
    line-height: 1.2;
}

.custum-sign-up-button a {
    color: #fff;
    background: rgb(243, 139, 22) !important;
    border-radius: 3px !important;
    border-color: rgb(243, 139, 22) !important;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 7px 30px;
    margin-right: 20px !important;
    font-size: 16px;
}

.custum-sign-up-button a:hover {
    color: #fff;
    background: #334a4a !important;
    border-radius: 3px !important;
    border-color: #334a4a !important;
    letter-spacing: 1px;
    font-weight: 600;
}

.instant-search-form {
    max-width: 450px;
    margin: 0px auto;
}

@media (max-width: 768px) {
    .custom-tooltip-helptext {
        width: 98% !important;
        left: 3px !important;
    }
}