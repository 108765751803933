.main-header{
    /* background: #3e5151 linear-gradient(27deg,#3e5151 0,#decba4); */
    /* background:#6200EA linear-gradient(27deg,#d1d1ff 0,#1515c8); */
    /* background: #55d linear-gradient(27deg,#00BCD4,#3F51B5); */
    /* background: #3f51b5 linear-gradient(27deg,#55d,#00bcd4); */
    background: #695aea linear-gradient(27deg,#5446cc,#a6a1d0);
}

.btn-clearout-phone-export,
.btn-clearout-phone-download {
    /* width: 240px;
    max-width: 240px; */
    align-self: stretch;
}

.cop-download-btn-shadow {
    background-color: #6200EA;
    border-color: #6200EA;
    color: #fff;
    text-decoration: underline;
}

.btn-clearout-phone {
    background-color: #6200EA;
    border-color: #6200EA;
    color: #fff;
    font-weight: 700;
}

.btn-clearout-phone:focus,
.btn-clearout-phone:hover {
    background-color: #5151ec;
    border-color: #5151ec;
    color: #fff
}

.public-btn-clearout-phone {
    background-color: #3ecf8e;
    border-color: #3ecf8e;
    color: #fff;
    font-weight: 700;
}

.btn-clearout-phone-refresh {
    /* padding: 3px 5px; */
    /* outline: none; */
    box-shadow: none;
    color: #717171 !important;
    border-color: #c3c3c3 !important;
}

.btn-clearout-phone:focus {
    outline: none;
    outline-color: transparent;
    box-shadow: none;
}

.btn-clearout-phone.disabled,
.btn-clearout-phone:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.btn-clearout-phone-link {
    background-color: transparent;
    border: none;
    /* text-decoration: underline !important; */
    color: #03a9f4 !important;
    font-weight: normal;
}

.btn-clearout-phone-link:hover {
    cursor: pointer;
}

.btn-clearout-phone-link:focus {
    color: #0b8df8;
    outline: none;
    box-shadow: none;
}

.dashboard-page-sidebar {
    background-color: #ffffff;
    margin-right: 10px;
}

.dashboard-page-sidebar.nav-tabs .nav-item {
    margin-bottom: 0 !important;
}

.dashboard-page-sidebar.nav-tabs .nav-link.active {
    background-color: transparent;
    color: #6200EA;
    border-right: 3px solid #6200EA;
}

.dashboard-page-sidebar.nav-tabs .nav-link {
    border-radius: 0;
    border-width: 0 0 1px;
    border-color: #e0e0e0;
    padding: 12px;
    font-size: 17px;
    color: #404040;
}

.dashboard-page-sidebar li a:hover {
    cursor: pointer;
}

.dashboard-page-content {
    margin-bottom: 15px;
    /* padding: 10px 0; */
    display: flex;
    flex-wrap: wrap;
}

.dashboard-page-content .infobox {
    background-color: #fff;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 1px solid #f5f5f5;
}

.dashboard-sub-sections .dashboard-qv-section{
    border:none;
    margin-right:10px;
    padding: 20px;
}

.dashboard-sub-sections .dashboard-bulk-section{
    border:none;
    margin-left:10px;
    padding: 20px;
}

@media(max-width: 768px) {

    .cop-quickvalidate-form{
        flex-direction: column;
    }

    .cop-qv-validate-button{
        margin: 10px;
    }

    .dashboard-sub-sections .dashboard-qv-section{
        border:none;
        margin-right:0px;
        padding: 20px;
    }

    .dashboard-sub-sections .dashboard-bulk-section{
        border:none;
        margin-left:0px;
        padding: 20px;
    }

    .dashboard-sub-sections{
        flex-direction: column;
    }
    .dashboard-page-sidebar {
        flex-direction: row !important;
        justify-content: space-evenly;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .dashboard-page-sidebar.nav-tabs .nav-link.active {
        border-right: 0px !important;
        border-bottom: 3px solid #6200EA;
    }
    .btn-clearout-phone-export,
    .btn-clearout-phone-download {
        width: 100%;
        max-width: 100%;
    }
}

.text-clearout-phone {
    color: #6200EA;
    font-weight: 600;
    font-size: 15px;
}

.text-clearout-phone-heading {
    color: #6200EA;
    font-weight: 600;
    font-size: 20px;
}

.cop-quickvalidate-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: #fff; */

}

.cop-quickvalidate-form{
    display: flex;
    align-items: center;
    justify-content: center;
}

.cop-support-text>span {
    color: #6200EA;
    font-family: 'Trebuchet MS', Helvetica, sans-serif;
    font-weight: 700;
}

.cop-performance-stats {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

.cop-performance-stats .stat-heading {
    display: block;
    text-align: left;
    font-size: 18px;
    color: #484848;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: "open_sanssemibold",Arial,"Helvetica Neue",Helvetica,sans-serif;
    letter-spacing: .6px;
    padding-bottom: 5px;
}

.cop-performance-stats .stat-value {
    display: block;
    font-size: 40px;
    color: #4c4c4c;
    margin-bottom: 0;
}

.cop-performance-stats .stats-bar {
    height: 3px;
    background: linear-gradient(to right, #00bcd4 0%, #6a9bcd 50%);
    width: 75%;
}

.cop-performance-stats {
    text-align: center;
    font-size: 30px !important;
    color: #334a4a !important;
    font-weight: 400;
    line-height: 1.2;
}

.cop-heading {
    text-align: center;
    font-size: 30px !important;
    color: #334a4a !important;
    font-weight: 400;
    line-height: 1.2;
}

.cop-custom-pricing-text {
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 0;
}

.cop-custom-pricing-text>a {
    color: #6200EA;
    font-weight: bold;
}

input.cop-coupon-input {
    /* box-shadow: 0 0 10px 1px #f8b467 !important; */
    border: 1px solid #6200EA !important;
}

input.cop-coupon-input:focus {
    box-shadow: 0 0 10px 1px #6200EA !important;
    border: 1px solid #6200EA !important;
}

.clearout-phone-hero-text{
    /* margin-bottom: 0; */
    font-size: 20px;
    font-family: "SofiaProRegular", sans-serif;
    text-align: center;
}


.clearout-phone-hero-text > span{
    font-weight: bold;
    color: #6200EA;
    text-transform: uppercase;
}

.focus-login-form-input::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height:2px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    background: #6200EA;
}

.cop-performance-stats-heading {
    font-size: 24px !important;
    color: #334a4a !important;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.2;
}

#quickvalidate-phone-page .register-phone-number input#clearout-phone-input {
    font-size: 20px;
    color: #222325d6;
    font-weight: 500;
}

.register-phone-number input#clearout-phone-input {
    height: 1.8rem;
    outline: none;
    border-radius: 0;
    padding: 0;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-bottom: 1px solid #c5d2e0;
    transition: border .1s;
    font-size: inherit;
}

.register-phone-number .PhoneInputCountry .PhoneInputCountrySelectArrow{
    display: block;
    content: "";
    width: 0;
    height: 0;
    margin-bottom: 0.1em;
    margin-top: 0.3em;
    margin-left: 0.3em;
    border-bottom-width: 0;
    border-top-width: 0.35em;
    border-bottom-style: solid;
    border-top-style: solid;
    border-left: 0.2em solid transparent;
    border-right: 0.2em solid transparent;
    color: #b8bdc4 !important;
    opacity: .7;
    transition: color .1s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.register-phone-number input#clearout-phone-input:focus{
    border-bottom: 2px solid #0909b9;
}

.public-cop-quickvalidate-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background:hsla(0, 0%, 0%, 0.17); */
    /* background: #fff; */

}

#public-quickvalidate-phone-page .register-phone-number input#clearout-phone-input{
    font-size: 20px;
    color: #222325d6;
    font-weight: 500;
}

input#clearout-phone-input::placeholder{
    color:#d0d0d0 !important;
}

input.cop-credit-input:focus {
    box-shadow: 0 0 10px 1px #0909b9 !important;
    border: 1px solid #0909b9 !important;
}

.public-input-phone-number{
    /* background: #ffffff2b; */
    padding: 5px;
}

.public-input-phone-number .PhoneInputCountry .PhoneInputCountryIcon > img{
    vertical-align: super;
}

.public-input-phone-number{
    align-items: flex-end;
}

.public-input-phone-number .PhoneInputCountry .PhoneInputCountryIcon {
    width: 40px !important;
    height: 30px !important;
    /* border: 1px solid rgba(0,0,0,.5); */
    box-sizing: initial !important;
}
.register-phone-number .PhoneInputCountry .PhoneInputCountryIcon {
    width: 40px !important;
    height: 30px !important;
    /* border: 1px solid rgba(0,0,0,.5); */
    box-sizing: initial !important;
}

.cop-result-table td{
    padding: 0.5em;

}

.logo-default, .main-header .logo-default {
    height: 100%;
    width: 180px;
}

.logo-header{
    width: 130px;
    height: 100%;
}

.cop-json-view{
    /* min-height:389px; */
    color: #fff;
    background-color:#101015de;
    padding:20px;
}
.cop-json-view span{
    color: #b9ca4a;
}
.cop-json-view label{
    color: #e78c45;
    margin-bottom: 0;

}


.select-dc-modal.modal-dialog{
    max-width: 400px;
}

.select-dc-modal .modal-content {
    height: 200px;
}

.flag-select img{
    top:0 !important;
    width: 2.3em !important;
    height: 2.3em !important;
}

.cop-flag-svg svg{
display: block;
height: 30px;
}
.cop-listdetails-flag-svg svg{
display: block;
height: 20px;
}